/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Image from 'gatsby-plugin-sanity-image';
import { Link, navigate } from 'gatsby';
import Lottie from 'lottie-react';
import Seo from '../SEO';
import { Footer } from '../components/footer';
import NoScript from '../components/NoScript';
import ButtonShareMobile from '../components/button/ButtonShareMobile';
import BlockText from '../components/blockText/BlockText';
import ButtonShareDesktop from '../components/button/ButtonShareDesktop';
import ButtonCta from '../components/button/ButtonCta';
import Navbar from '../components/navbar';
import { urlFor } from '../utils/common';
import loading from '../assets/loading.json';
import Wrapper from '../components/Wrapper';

const BlogDetail = ({ location }) => {
  const [thisURL, setThisUrl] = useState('');
  const [data, setData] = useState(null);
  const params = new URLSearchParams(location.search);

  const fetchSanity = async () => {
    const query = encodeURIComponent(`*[ _type == "blog" || _type="promo"  && _id=="${params.get('id')}"] {
        _id,
        lang,
        'subCategories': subCategories[]->title,
        category,
        mainImageURL,
        title,
        mainImage,
        publishedAt,
        body,
        'author': author->name,
    }`);

    const sanityData = await fetch(`/api-sanity?query=${query}`, {
      method: 'get',
    }).then((res) => res.json());
    if (sanityData.result.length === 0) {
      navigate('/');
      return;
    }
    setData(sanityData.result[0]);
  };
  useEffect(() => {
    setThisUrl(window.location.href);

    if (!params.get('id')) {
      navigate('/');
      return;
    }
    fetchSanity();
  }, []);
  if (data === null) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Lottie animationData={loading} className=" w-1/6 h-auto" />
      </div>
    );
  }

  return (
    <Wrapper>
      <Seo
        lang={data.lang}
        title={data.title}
        metaDescription={data.title}
        metaImage={urlFor(data.mainImage.asset).url()}
        type="website"
        postData={data}
      />
      <Navbar />
      <div className="container mx-auto relative p-0">
        <div className="hidden absolute md:block lg:block pl-5 my-12">
          <ButtonShareDesktop url={thisURL} />
        </div>
        <div className="mx-auto w-11/12 md:w-8/12 lg:w-8/12">
          <div className="my-4 md:my-5 lg:my-5 promo-label text-secondary capitalize">
            <span>{data.category}</span>
          </div>
          <div className="text-2xl font-bold md:text-4xl lg:text-4xl text-primary">
            <span>{data.title}</span>
          </div>
          <div className="text-sm font-bold my-3 text-primary">
            <span>{data.author}</span>
          </div>
          <div className="text-sm text-primary mb-10">
            <span>{data.publishedAt}</span>
          </div>
          {data.mainImageURL !== null
            ? (
              <div className="w-full mb-16">
                <Link to={data.mainImageURL} className="hover:no-underline cursor-pointer">
                  <Image {...data.mainImage} title={data.title} />
                </Link>
              </div>
            )
            : (
              <div className="w-full mb-16">
                <Image {...data.mainImage} title={data.title} />
              </div>
            )}
          <BlockText body={data.body} title={data.title} />
          <ButtonShareMobile url={thisURL} />
          <div className="flex flex-row justify-center w-full mt-10 mb-20">
            {Array.isArray(data.buttonCtas) && data.buttonCtas.map((btn) => (
              <ButtonCta {...btn} />
            ))}
          </div>
        </div>
      </div>
      <Footer showConnectWithUs={false} />
      <NoScript />
    </Wrapper>
  );
};

BlogDetail.propTypes = {

};

BlogDetail.defaultProps = {

};

export default BlogDetail;
